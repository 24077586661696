.description-romain{
    color: white;
    display: flex;
    justify-content: flex-end;
}

.description-romain p{
    background-color: #273D6C;
    padding: 20px;
    font-weight: 200;
    width: 65%;
    border-radius: 10px;
}