/* Mobile S */
@media only screen and (min-width: 320px) and (max-width: 480px) {

}

/* Mobile M */
@media only screen and (min-width: 481px) and (max-width: 768px) {

}


/* Tablet */
@media only screen and (max-width: 1025px){
    .link-container, .description-romain p{width: 60% !important;}
    #main{max-width: 1090px !important;}
    .pp-container{display: none;}
    .link-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        flex-direction: column;
    }
    .socials-container{margin-left: 0px !important; width: 120px !important; justify-content: space-evenly !important;}
    .description-romain{justify-content: center !important;}
    .columns-container.inline{margin-top: 30px !important; flex-direction: column;}
    .col-1.skills-container{width: 90% !important; margin: 0 auto !important;}
    .col-2.infos-container {
        width: 90% !important;
        margin: 0 auto !important;
        margin-top: 40px !important;
    }
}

/* Mobile L */
@media only screen and (max-width: 769px) {
    
    .contact-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .col-1.experiences-container, .col-2.formations-container, .col-2.contact-container{padding: 20px !important;}

    .link-container, .description-romain p {
        width: 100% !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

}


/* Laptop */
@media only screen and (min-width: 1281px) and (max-width: 1440px) {

}

/* Laptop L */
@media only screen and (min-width: 1441px) and (max-width: 1920px) {

}

