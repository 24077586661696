.primary-nav {
    position: relative;
    display: flex;
    background-color: #14213D;
    height: 100px;
    border-bottom: solid 0.8px #273D6C;
}

.nav-container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
}

.pp-container{position: relative;}

.pp-container figure{
    position: absolute;
}

.pp-container figure::after{
    content: '';
    width: 360px;
    height: 360px;
    left: 0px;
    position: absolute;
    background-color: #273D6C;
    z-index: 0;
    border-radius: 100%;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pp-container figure::before {
    content: '';
    width: 400px;
    height: 168px;
    position: absolute;
    background-color: #14213D;
    z-index: 50;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pp-container figure img{
    position: relative;
    border-radius: 100%;
    width: 270px;
    height: 270px;
    object-fit: cover;
    z-index: 55;
    filter: grayscale(1);
}

.link-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 68%;
}

.link-container a{
    color: #FFFF;
    text-decoration: none;
    font-size: 17px;
    font-weight: 400;
    transition: color 0.3s;
    padding-bottom: 5px;
}

.link-container a::before{
    content: ''; 
    position: absolute;
    bottom: 0; 
    left: 0; 
    width: 0%;
    height: 2px; 
    background-color: #FCA311;
    transition: width 0.3s;
    z-index: 52;
    bottom: 0px;
}

.link-container a:hover{
    color: #FCA311;
}

.link-container a:hover::before {
    width: 100%;
}

.socials-container{
    display: flex;
    width: 12%;
    justify-content: space-around;
    align-items: center;
    margin-left: 14px;
}

.socials-container svg{
    width: 30px;
    height: 30px;
    fill: #FCA311;
}

.socials-container svg:hover{fill: #273D6C;}