#main{max-width: 1200px; margin: 0 auto; color: white;}

.columns-container.inline{
    width: 100%;
    margin-top: 130px;
    display: flex;
    justify-content: space-between;
}

.col-1.skills-container, .col-1.experiences-container, .col-2.formations-container, .col-2.contact-container{
    border: solid 2px #273D6C;
    border-radius: 10px;
}

.col-1.skills-container {
    width: 25%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}
.col-2.infos-container{width: 73%; margin-left: 10px;}

.col-1.experiences-container, .col-2.formations-container, .col-2.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.col-2.contact-container{margin-top: 20px; padding-bottom: 57px;}

.col-2.formations-container{margin-top: 20px;}

.single-skill {
    display: flex;
    align-items: center;
    background-color: #273D6C;
    border-radius: 10px;
    justify-content: space-evenly;
    color: white;
    margin-top: 20px;
}

.skillInfos h3{margin-bottom: 0px;}
.skillInfos p{margin-top: 0px;}

.skill-category-container {
    width: 85%;
}

.skill-category-container h2{
    color: white;
}

.single-skill img {
    width: 32px;
}

h2, h3{font-weight: 400;}

p{font-weight: 200;}

.phone-container, .mail-container{display: flex; align-items: center;}

svg#Capa_1{width: 40px; margin-right: 10px;}

a{text-decoration: none; color: #fca311;}

.mail-container svg{margin-top: 4px;}

.contact-wrapper{display: flex; justify-content: space-evenly; width: 80%;}

.contact-container p, .contact-container a {
    font-size: 25px;
}

div#content {
    padding-bottom: 80px;
}