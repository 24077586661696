.single-formation {
    color: white;
    display: flex;
    width: 91%;
    flex-direction: column;
    align-items: flex-start;
    background-color: #273D6C;
    border-radius: 10px;
    margin-top: 20px;
    padding: 5px 20px;
}

.tag.formation {
    background: #FCA311;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 13px;
    color: #273D6C;
}